import type { ReactNode } from 'react';
import cn from 'classnames';
import { useVisibility } from '~/shared';
import { VisibilityName } from '~/store';
import * as mesraService from '~/domain/mesra';
import { IconButton, PetronasButton, Icon } from '~/components';
import { CrossIcon, Modal } from '@setel/web-ui';
import { useTranslation } from 'react-i18next';

export const WelcomeMesra = () => {
  let { t } = useTranslation();
  const modal = useVisibility(VisibilityName.MESRA_WELCOME);

  const hideWelcomePopup = () => {
    mesraService.hideWelcomeMesraPopup();
    modal.close();
  };

  type LearnMoreButtonProps = { className?: string };
  const LearnMoreButton = ({ className }: LearnMoreButtonProps) => (
    <PetronasButton
      className={className}
      size="large"
      render={(props) => (
        <a
          href="https://www.setel.com/mesra-membership-tiers"
          rel="noopener noreferrer"
          target="_blank"
          {...(props as any)}
        />
      )}
    >
      {t('buttons.learnMore')}
    </PetronasButton>
  );

  return (
    <>
      <Modal
        isOpen={modal.isOpen}
        onDismiss={hideWelcomePopup}
        aria-label="Welcome to Mesra Rewards"
        className="md:w-[580px] md:rounded-t-2xl overflow-hidden"
      >
        <Modal.Body className="p-0 relative">
          <div className="relative flex justify-center bg-brand-500 md:rounded-t-lg">
            <img
              alt=""
              loading="lazy"
              decoding="async"
              src="/assets/welcome-modal/welcome-mesra-3x.png"
              className="block w-full"
              width={580}
              height={248}
            />
            <IconButton
              aria-label="Close popup"
              onClick={hideWelcomePopup}
              className="text-white absolute right-0 mt-4 mr-4"
              children={<CrossIcon />}
            />
          </div>

          <div className="px-5 md:px-10 py-5 md:pb-10 md:pt-7">
            <WelcomeMesraContent
              title={t('welcomeMesra.title')}
              listItems={[
                t('welcomeMesra.content1'),
                t('welcomeMesra.content2'),
                t('welcomeMesra.content3'),
              ]}
            />
            <div className="flex gap-4 flex-col md:flex-row-reverse mt-6 md:mt-9">
              <LearnMoreButton className="w-full" />
              <PetronasButton
                size="large"
                variant="outline"
                onClick={hideWelcomePopup}
                className="w-full"
              >
                {t('buttons.later')}
              </PetronasButton>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

type WelcomeMesraContentProps = {
  title: string;
  listItems: ReactNode[];
};

const WelcomeMesraContent = ({
  title,
  listItems,
}: WelcomeMesraContentProps) => {
  return (
    <>
      <h2 className="font-title text-xl text-center font-semibold md:text-2xl leading-7 md:leading-8">
        {title}
      </h2>
      <ul className="list-none mt-4 md:mt-5">
        {listItems.map((ele, index) => (
          <li
            key={index}
            className={cn('flex justify-start', {
              'mt-3': index,
            })}
          >
            <div className="w-6 h-6 mr-2">
              <Icon name="status" className="text-petronas-brand w-6 h-6" />
            </div>
            <p className="text-sm md:text-base text-gray-medium col-span-11 leading-5 md:leading-6 no-underline">
              {ele}
            </p>
          </li>
        ))}
      </ul>
    </>
  );
};
