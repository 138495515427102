import { apiClient } from '../../shared/axios';
import { HttpStatus } from '../../shared/httpStatus';
import { getErrorStatusCode, ValidationError } from '../error';
import { GetMesraMembersParams, GetMesraMembersResponse } from './mesra.type';

export const IS_WELCOME_MESRA_MODAL_HIDDEN = 'hide-welcome-mesra';

export const hideWelcomeMesraPopup = () => {
  sessionStorage.setItem(IS_WELCOME_MESRA_MODAL_HIDDEN, String(true));
};

export const isWelcomeMesraPopupVisible = (): boolean => {
  return sessionStorage.getItem(IS_WELCOME_MESRA_MODAL_HIDDEN) === 'true';
};

export const getMesraMembers = (params: GetMesraMembersParams) => {
  return apiClient
    .get<GetMesraMembersResponse>('/loyalty/loyalty-members', {
      params,
      public: true,
    })
    .then((resp) => resp.data?.data)
    .catch((error) => {
      if ([HttpStatus.BAD_REQUEST].includes(getErrorStatusCode(error))) {
        throw new ValidationError({
          idNumber: [error.response?.data.message as string],
        });
      }

      throw error;
    });
};
