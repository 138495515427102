import cn from 'classnames';
import { useIsAuthorized } from '~/features/Auth';
import { Button } from '@setel/web-ui';
import { PointsAndLevel } from './PointsAndLevel';
import heroSm from './assets/reward-hero-sm.png';
import heroMd from './assets/reward-hero-md.png';
import heroLg from './assets/reward-hero-lg.png';
import heroXl from './assets/reward-hero-xl.png';
import mesraRewardIcon from './assets/mesra-reward-icon.svg';
import { Trans, useTranslation } from 'react-i18next';

export const RewardBanner = ({ className }: { className?: string }) => {
  const isAuthorized = useIsAuthorized();
  let { t } = useTranslation();

  return (
    <section
      className={cn(
        'bg-[#FCE0EB] bg-cover bg-center bg-no-repeat overflow-x-hidden',
        className
      )}
    >
      <div className="container mx-auto pt-8 lg:pt-7">
        <div className="flex flex-col text-center lg:text-left lg:flex-row lg:items-center xl:px-[16px]">
          <div className="px-5 lg:px-0">
            <img
              src={mesraRewardIcon}
              className="md:w-[200px] md:h-[76px] w-[160px] h-[61px] mx-auto lg:mx-0 mb-7"
            />
            <div className="lg:px-0">
              <h1 className="font-secondary font-bold mb-2 text-[28px] leading-[38px] md:text-[44px] md:leading-[58px] ">
                <Trans i18nKey="rewardBanner.title">
                  Triple the points,
                  <br className="hidden md:inline" />
                  triple the happiness!
                </Trans>
              </h1>
              <p className="font-secondary font-medium text-base md:text-lg">
                <Trans i18nKey="rewardBanner.subtitle">
                  Earn up to 3x more Mesra points when you pay with Setel.
                  <br className="hidden md:inline lg:hidden xl:inline" />
                  The more points you earn, the greater rewards you can unlock!
                </Trans>
              </p>
            </div>

            <div className="mt-7 min-h-[56px]">
              {isAuthorized ? (
                <PointsAndLevel />
              ) : (
                <Button
                  className="mx-auto font-secondary text-sm font-bold"
                  style={{
                    width: 156,
                    height: 56,
                  }}
                  size="medium"
                  variant="primary"
                  render={({ ref, ...props }) => (
                    <a
                      href="https://www.setel.com/mesra-membership-tiers"
                      target="_blank"
                      rel="noopener noreferrer"
                      ref={ref as any}
                      {...props}
                    />
                  )}
                >
                  {t('buttons.learnMore')}
                </Button>
              )}
            </div>
          </div>

          <div className="mt-4 md:mt-0">
            <picture>
              <source
                media="(min-width: 1280px)"
                width={642}
                height={532}
                srcSet={heroXl}
              />
              <source
                media="(min-width: 1024px) and (max-width: 1279px)"
                width={473}
                height={447}
                srcSet={heroLg}
              />
              <source
                media="(min-width: 768px) and (max-width: 1023px)"
                width={670}
                srcSet={heroMd}
              />
              <source
                media="(min-width: 640px) and (max-width: 767px)"
                width={640}
                srcSet={heroMd}
              />
              <img
                alt="Banner image"
                width={375}
                className="lg:max-w-none"
                src={heroSm}
                loading="lazy"
                decoding="async"
              />
            </picture>
          </div>
        </div>
      </div>
    </section>
  );
};
