import type { FC } from 'react';
import { Tier } from '../../../domain/membership/membership.type';

export type LevelProps = {
  currentTier: Tier;
};

export const Level: FC<React.PropsWithChildren<LevelProps>> = ({
  currentTier,
}) => {
  return (
    <div className="p-3 md:p-4 flex items-center bg-transparent border border-[#2D333A] rounded-[20px]">
      <img
        alt="membership-tier"
        className="mr-2 w-6 h-6"
        loading="lazy"
        decoding="async"
        src={currentTier.icons.primary}
      />
      <span className="md:mt-1">{currentTier.title}</span>
    </div>
  );
};
