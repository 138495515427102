import { Loader } from '@setel/web-ui';
import { LoadMore } from '~/components/LoadMore';
import { useCallback } from 'react';
import { CataloguePreview } from '../DealCatalogue';
import { useCatalogues } from './rewards.queries';
import { trackUserEvent, useIntersectionObserver } from '~/shared';
import { EVENT_TYPES } from '~/shared/analytics.events';

export const Catalogues = () => {
  const {
    data,
    fetchNextPage: fetchMore,
    hasNextPage: canFetchMore,
    isFetchingNextPage: isFetchingMore,
    isLoading,
  } = useCatalogues();

  const pages = data?.pages;

  const setTarget = useIntersectionObserver({
    callback: useCallback(({ isIntersecting }: any, observer: any) => {
      if (isIntersecting) {
        trackUserEvent(EVENT_TYPES.deal_deal_order_view_main_page);
        observer?.disconnect();
      }
    }, []),
  });

  if (isLoading) {
    return (
      <div className="flex justify-center my-6">
        <Loader type="spinner-blue" />
      </div>
    );
  }

  return (
    <div ref={setTarget}>
      {pages?.flatMap(({ data }) =>
        data.map(
          (catalogue) =>
            catalogue.deals.length > 0 && (
              <CataloguePreview
                className="py-6"
                key={catalogue._id}
                {...catalogue}
              />
            )
        )
      )}
      <LoadMore
        className="mb-10"
        onFetchMore={fetchMore}
        loading={Boolean(isFetchingMore)}
        show={Boolean(canFetchMore)}
      />
    </div>
  );
};
