import cn from 'classnames';
import { Arrow } from '~/features/DealCatalogue/CataloguePreview/Arrow';
import { useVisibility } from '~/shared';
import { useIsAuthorized } from '~/features/Auth';
import { VisibilityName } from '~/store';
import { useScrollSnapSlider } from '~/components/ScrollSnapSlider';

export const FeaturedBanner = () => {
  const isAuthorized = useIsAuthorized();
  const authModal = useVisibility(VisibilityName.AUTH);

  const items = [
    <a
      href="https://www.setel.com/mesrarewardsregister"
      rel="noopener noreferrer"
      target="_blank"
    >
      <img
        className="focus:outline-none rounded-lg"
        src="/assets/featured-banner/existing-physical-card.png"
        alt="Existing physical mesra card member?"
        decoding="async"
        loading="lazy"
      />
    </a>,
    <a
      href="https://www.setel.com/promotions/cash-top-up/"
      rel="noopener noreferrer"
      target="_blank"
    >
      <img
        className="focus:outline-none rounded-lg"
        src="/assets/featured-banner/cash-top-up.png"
        alt="cash-top-up"
        decoding="async"
        loading="lazy"
      />
    </a>,
    <img
      role={isAuthorized ? undefined : 'button'}
      aria-label={isAuthorized ? '' : 'Existing setel user? Login now'}
      alt={isAuthorized ? '' : 'Existing setel user? Login now'}
      onClick={() => {
        if (!isAuthorized) {
          authModal.open();
        }
      }}
      className={cn('focus:outline-none rounded-lg', {
        'cursor-pointer': !isAuthorized,
      })}
      src="/assets/featured-banner/existing-user.png"
      decoding="async"
      loading="lazy"
    />,
  ];

  const {
    sliderProps,
    getSlideItemProps,
    canNext,
    canPrev,
    goToNextSlide,
    goToPrevSlide,
  } = useScrollSnapSlider({
    containerMode: true,
    slideBaseWidth: 374,
    slideSpacing: 16,
  });

  return (
    <>
      <div className="-mx-5 sm:mx-0 relative">
        <div className="hidden sm:block">
          {canPrev && <Arrow onClick={goToPrevSlide} />}
          {canNext && <Arrow next onClick={goToNextSlide} />}
        </div>

        <nav
          aria-label="Featured items"
          className="featured-slider"
          {...sliderProps}
        >
          {items.map((item, i) => (
            <div key={i} className="p-1" {...getSlideItemProps(i)}>
              {item}
            </div>
          ))}
        </nav>
      </div>
    </>
  );
};
