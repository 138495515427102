import { Icon } from '~/components/Icon';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

export type PointsProps = {
  balance?: number;
};

export const Points: FC<React.PropsWithChildren<PointsProps>> = ({
  balance,
}) => {
  const { t } = useTranslation();

  return (
    <div className="p-3 md:p-4 flex items-center bg-transparent rounded-[20px] border border-[#2D333A]">
      <Icon
        className="inline align-top mr-2"
        name="reward"
        aria-label="Mesra points"
      />

      <span className="md:mt-1">
        {balance !== undefined ? new Intl.NumberFormat().format(balance) : '0'}{' '}
        {t('points.mesraPoints')}
      </span>
    </div>
  );
};
