import { Rewards } from '~/features/Rewards';
import type { FooterSMSFormRouteHandle } from '~/features/Footer';
import { mergeMeta } from '~/lib/utils/merge-remix-meta';

export const handle: FooterSMSFormRouteHandle = {
  footerSMSForm: { className: 'bg-gray-100' },
};

export const meta = mergeMeta(() => {
  return [
    { title: 'PETRONAS Mesra Rewards is now on Setel' },
    {
      name: 'description',
      content: `Redeem your PETRONAS Mesra points for exciting rewards on the Setel app or web today!`,
    },
  ];
});

export default function MesraRewards() {
  return (
    <>
      <Rewards />
    </>
  );
}
