// https://gist.github.com/ryanflorence/ec1849c6d690cfbffcb408ecd633e069

import type { LoaderFunction, MetaFunction } from '@remix-run/node';
import { defaultOnError } from '~/domain/error';

export const mergeMeta = <
  Loader extends LoaderFunction | unknown = unknown,
  ParentsLoaders extends Record<string, LoaderFunction> = {}
>(
  leafMetaFn: MetaFunction<Loader, ParentsLoaders>
): MetaFunction<Loader, ParentsLoaders> => {
  return (arg) => {
    let leafMeta = leafMetaFn(arg);

    // guard against runtime error where passed meta might not be an array
    if (!Array.isArray(leafMeta)) {
      defaultOnError(
        `Expected \`meta\` to be an array, got: ${leafMeta}.\n` +
          `Location: ${arg.location.pathname}${arg.location.search}`
      );
      leafMeta = [];
    }

    const mergedMeta = arg.matches.reduceRight((acc, match) => {
      for (let parentMeta of match.meta) {
        let index = acc.findIndex(
          (meta) =>
            ('name' in meta &&
              'name' in parentMeta &&
              meta.name === parentMeta.name) ||
            ('property' in meta &&
              'property' in parentMeta &&
              meta.property === parentMeta.property) ||
            ('title' in meta && 'title' in parentMeta) ||
            ('charSet' in meta && 'charSet' in parentMeta) ||
            ('httpEquiv' in meta && 'httpEquiv' in parentMeta)
        );
        if (index === -1) {
          // Parent meta not found in acc, so add it
          acc.push(parentMeta);
        }
      }
      return acc;
    }, leafMeta);

    return mergedMeta;
  };
};
