import cn from 'classnames';
import { useIsAuthorized } from '~/features/Auth';
import * as mesraService from '~/domain/mesra';
import { WelcomeMesra } from '~/features/Mesra/WelcomeMesra';
import { useAccountAttributes } from '~/features/Attributes';
import { useEffect } from 'react';
import { useVisibility } from '~/shared';
import { useZendeskTagEffect } from '~/shared/hooks/useZendeskTag';
import { VisibilityName, ZendeskTagsName } from '~/store';
import { Catalogues } from './Catalogues';
import { FeaturedBanner } from './FeaturedBanner';
import { RewardBanner } from './RewardBanner';
import { DownloadAppBanner } from '../DownloadAppBanner';
import { useVariables } from '~/domain/variable';

export const Rewards = () => {
  const isAuthorized = useIsAuthorized();
  const modal = useVisibility(VisibilityName.MESRA_WELCOME);

  const { data: variables } = useVariables();
  const { accountAppInstall: isAppInstalled } = useAccountAttributes();

  useZendeskTagEffect(ZendeskTagsName.REWARDS_HOME);

  useEffect(() => {
    if (!isAuthorized && !mesraService.isWelcomeMesraPopupVisible()) {
      modal.open();
    }
  });

  return (
    <>
      {!variables?.web_mesrarewards_welcome_modal_hidden?.value && (
        <WelcomeMesra />
      )}

      <RewardBanner />

      {!isAppInstalled && (
        <DownloadAppBanner
        // TODO: temp hide https://setelnow.atlassian.net/browse/GA-767
        // className={cn({ 'pt-14': isAuthorized })}
        />
      )}

      <div
        className={cn(
          'flex flex-col flex-grow relative bg-gray-100 pt-8 md:pt-16'
        )}
      >
        <div className="container mx-auto">
          <FeaturedBanner />
          <Catalogues />
        </div>
      </div>
    </>
  );
};
