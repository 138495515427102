import { useGetMesraBalance } from '~/domain/card';
import { defaultOnError } from '~/domain/error';
import { getTierProgress } from '~/domain/membership/membership.service';
import { useQuery } from 'react-query';
import { FadeInDown } from '../../../components/Transition';
import { Level } from './Level';
import { Points } from './Points';

export const PointsAndLevel = ({ className = '' }: { className?: string }) => {
  const { data: balance } = useGetMesraBalance();
  const { data: tier } = useQuery('getTierProgress', getTierProgress, {
    onError: defaultOnError,
  });

  return (
    <>
      <FadeInDown show={!!tier || !!balance}>
        <div
          className={
            'flex flex-wrap justify-center lg:justify-start gap-4 md:gap-5 w-full text-[#2D333A] font-secondary font-medium md:font-semibold md:tracking-[1.82px] md:uppercase text-lg md:text-sm  ' +
            className
          }
        >
          <Points balance={balance?.pointTotalBalance} />

          {tier?.currentTier && <Level currentTier={tier.currentTier} />}
        </div>
      </FadeInDown>
    </>
  );
};
