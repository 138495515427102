import { useInfiniteQuery } from 'react-query';
import { defaultOnError } from '~/domain/error';
import { listCataloguesWithDeals } from '~/domain/catalogue';

export const queryCatalogues = {
  queryKey: 'listCataloguesWithDeals',
  queryFn: ({ pageParam: pageToken = '1' }) =>
    listCataloguesWithDeals({ pageToken }),
};

const useCataloguesHook = () => {
  return useInfiniteQuery(queryCatalogues.queryKey, queryCatalogues.queryFn, {
    onError: defaultOnError,
    getNextPageParam: (last) => last.nextPageToken,
  });
};

export const useCatalogues = Object.assign(useCataloguesHook, queryCatalogues);
